export default {
    getLastSunday() {
        let date = new Date();
        let daysSinceSunday = date.getDay();
        date.setDate(date.getDate() - daysSinceSunday);
        return date.toLocaleDateString();
    },

    getSundayXWeeksAgo(weeksAgo) {
        let date = new Date();
        let daysSinceSunday = date.getDay();
        date.setDate(date.getDate() - (daysSinceSunday + (7 * weeksAgo)));
        return date.toLocaleDateString();
    },

    getFirstOfTheMonth() {
        let date = new Date();
        date.setDate(1);
        return date.toLocaleDateString();
    },

    getLastOfTheMonth() {
        let date = new Date();
        return new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
        ).toLocaleDateString();
    },
    
    getFirstOfTheYear() {
        let date = new Date();
        date.setMonth(0);
        date.setDate(1);
        return date.toLocaleDateString();
    },

    getElevenMonthsAgo(day = 1) {
        let date = new Date();
        date.setMonth(date.getMonth() - 11);
        date.setDate(day)
        return date.toLocaleDateString();
    },

    getXMonthsAgo(monthsAgo) {
        let date = new Date();
        date.setMonth(date.getMonth() - monthsAgo);
        return date.toLocaleDateString();
    },

    getMonthsArray() {
        return [
            [1, 'January'],
            [2, 'February'],
            [3, 'March'],
            [4, 'April'],
            [5, 'May'],
            [6, 'June'],
            [7, 'July'],
            [8, 'August'],
            [9, 'September'],
            [10, 'October'],
            [11, 'November'],
            [12, 'December']
        ]
    },

    getYearRangeArray(startYear, endYear) {
        let options = [];
        for (let i = startYear; i <= endYear; i++) {
            options.push(i);
        }
        return options;
    },
    
    getToday() {
        return new Date().toLocaleDateString();
    },

    getTomorrow() {
        return new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString();
    },
    
    /**
     * @return {Date}
     */
    getDateTime(dateTime) {
        let stringArray = dateTime.split(' ');
        let dateString = stringArray[0];
        let timeString = stringArray[1].split(':');
        let hours = timeString[0] ? timeString[0] : '00';
        let minutes = timeString[1] ? timeString[1] : '00';
        
        let date = new Date(dateString);
        date.setHours(hours);
        date.setMinutes(minutes);
        
        return date;
    },
    
    /**
      * @param {Date} date
     *
     * @return {String}
     */
    dateFormatTimePicker (date) {
        let dateString = date.toLocaleDateString();
        let dateArray = dateString.split('/');
        let day = dateArray[0];
        let month = dateArray[1];
        let year = dateArray[2];
        
        if (day < 10) {
            day = `0${day}`;
        }
        
        if (month < 10) {
            month = `0${month}`;
        }
        
        dateString = `${day}/${month}/${year}`
        
        let fullTimeArray = date.toLocaleTimeString().split(' ');
        
        let hoursMinutesArray = fullTimeArray[0].split(':');
        let hours = hoursMinutesArray[0];
        let minutes = hoursMinutesArray[1];
        let amPM = fullTimeArray[1];
        
        if (amPM === 'PM' && hours !== '12') {
            hours = parseInt(hours) + 12;
        }
    
        if (hours < 10) {
            hours = `0${hours}`;
        }
        
        return `${dateString} ${hours}:${minutes}`;
    }
}